import React, { useState } from "react";

const FaqAccordian = ({ icon, title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item my-[10px] bg-[#fff]">
      <div
        className="flex flex-row justify-between "
        onClick={() => setIsActive(!isActive)}
      >
        <div className="">
          <div className="Crimson-Text !text-base md:!text-[24px] font-normal">
            {title}
          </div>
        </div>
        <button className="!text-[#737C87] font-bold !text-xl">
          {isActive ? "-" : "+"}
        </button>
      </div>
      {isActive && (
        <div className="dropdownText  mt-[20px] text-left">{content}</div>
      )}
    </div>
  );
};

export default FaqAccordian;
