import React, { useState } from "react";
import Chart from "react-apexcharts";
const DonutChartEnergy = () => {
  const [isActive, setIsActive] = useState(false);
  const data = {
    series: [24, 2, 1, 4, 8, 8, 24, 12, 8, 9], //24
    options: {
      chart: {
        width: "100%",
        height: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          expandOnClick: true,
          startAngle: -90,
          endAngle: 270,
          donut: {
            // size: "75%",
            labels: {
              show: true,
              name: { show: true },
              value: { show: false },
              total: {
                show: true,
                fontSize: "22px",
                fontWeight: 700,
                fontFamily: "Poppins",
                showAlways: true,
                label: "By 2024, 45GW",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        type: "solid",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
        },
        position: "left",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 600,
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 2,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          colors: "#2C2929",
          // useSeriesColors: false
        },
      },
      labels: [
        "Hydro",
        "Solar PV",
        "Baggase",
        "Wind",
        "Nuclear",
        "Gas",
        "RLNG",
        "Furnace Oil",
        "Local coal",
        "Imported Coal",
        // "Electricity Imports",
      ],
      colors: [
        "#00C9FB",
        "#FBFB00",
        "#5F5E5F",

        "#00AD4E",
        "#7901B4",
        "#2F0998",
        "#BD0100",
        "#FBBD00",
        "#9E6201",
        "#643200",
        "#30FFF3",
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "left",
            },
          },
        },
      ],
    },
  };
  const data2 = {
    series: [38, 10, 2, 3, 8, 5, 15, 2, 6, 9, 2], //1
    options: {
      chart: {
        width: "100%",
        height: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            // size: "75%",
            labels: {
              show: true,
              name: { show: true },
              value: { show: false },
              total: {
                show: true,
                fontSize: "22px",
                fontWeight: 700,
                fontFamily: "Poppins",
                showAlways: true,
                label: "By 2034, \u000A 60GW",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        type: "solid",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
        },
        position: "right",
        fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 600,
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 2,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          colors: "#2C2929",
          // useSeriesColors: false
        },
      },
      labels: [
        "Hydro",
        "Solar PV",
        "Baggase",
        "Wind",
        "Nuclear",
        "Gas",
        "RLNG",
        "Furnace Oil",
        "Local coal",
        "Imported Coal",
        "Cross border",
        // "Electricity Imports",
      ],
      colors: [
        "#00C9FB",
        "#FBFB00",
        "#5F5E5F",

        "#00AD4E",
        "#7901B4",
        "#2F0998",
        "#BD0100",
        "#FBBD00",
        "#9E6201",
        "#643200",
        "#30FFF3",
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "right",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="grid w-full h-full gap-4 grid-cols-1 lg:grid-cols-2">
      <div>
        <Chart
          options={data.options}
          series={data.series}
          type="donut"
          // height={450}
        />
        {/* <div className="absolute">By 2023</div> */}
      </div>
      <div>
        <Chart
          options={data2.options}
          series={data2.series}
          type="donut"
          // height={450}
        />
      </div>
    </div>
  );
};

export default DonutChartEnergy;
