import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import "./NavBar.css";
import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
import logo from "../assests/images/Vector(3).svg";
import logo1 from "../assests/images/logo1.svg";
import Popup from "./subcomponents/Popup";
function NavBar() {
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const languages = [
    { code: "en", name: "Eng" },
    { code: "ar", name: "Ara" },

    { code: "ur", name: "Chn" },

    // Add other languages as needed
  ];

  const [isOpenAbouts, setIsOpenAbouts] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleDropdownToggle = () => {
    setIsOpenAbouts(!isOpenAbouts);
  };

  const handleMouseEnter = () => {
    setIsOpenAbouts(true);
  };

  const handleClickisOpenAbouts = () => {
    // Handle any click logic if needed
  };

  // State to manage whether the link is clicked or hovered
  const [isClicked, setIsClicked] = useState(false);

  // Function to handle mouse leaving
  const handleMouseLeave1 = () => {
    setIsHovered(false);
  };

  const [click, setClick] = useState(false);

  // ... (existing language and dropdown state handlers)

  // State and handlers for hover effect on "About Us" link
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setClick(!click);
  };
  const handleClick1 = () => setClick(!click);
  const location = useLocation();

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleClick2 = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  return (
    <nav className="navbar1 h-24 md:h-40 top-0 start-0 !bg-transparent">
      <div className="nav-container">
        <NavLink exact to="/" className="nav-logo">
          {/* <span>CodeBucks</span> */}
          {/* <i className="fas fa-code"></i> */}
          {/* <span className="icon">
            {location.pathname === "/AgricutureSectors" ||
            location.pathname === "/success_stories/details" ||
            location.pathname == "/event/:id" ||
            location.pathname === "/news/:id" ||
            location.pathname === "/IndustryMainPage" ||
            location.pathname === "/ITmainPage" ||
            location.pathname === "/FAQs" ||
            location.pathname === "/MineralsMainPage" ||
            location.pathname === "/EnergyMainPage" ||
            location.pathname === "/invesment_climate" ||
            location.pathname === "/Establishbusinessinpakistan" ||
            location.pathname === "/insights" ? (
              <img src={logo1} className=""></img>
            ) : scrolling ? (
              <img src={logo1} className=""></img>
            ) : (
              <img src={logo} className=""></img>
            )}
          </span> */}
          <span className="icon">
            {location.pathname === "/agricuture-sector" ||
            location.pathname === "/success_stories/details" ||
            location.pathname.startsWith("/event/") ||
            location.pathname.startsWith("/news/") ||
            location.pathname.startsWith("/success_stories/") ||
            location.pathname.startsWith("/press-release/") ||
            location.pathname === "/IndustryMainPage" ||
            location.pathname === "/ITmainPage" ||
            location.pathname === "/FAQs" ||
            location.pathname === "/MineralsMainPage" ||
            location.pathname === "/EnergyMainPage" ||
            location.pathname === "/invesment_climate" ||
            location.pathname === "/Establishbusinessinpakistan" ||
            location.pathname === "/insights" ||
            scrolling ? (
              <img src={logo1} className="" alt="Logo 1" />
            ) : (
              <img src={logo} className="" alt="Default Logo" />
            )}
          </span>
        </NavLink>

        <div className={`nav-menu ${click ? "active" : ""} `}>
          <ul
            className={`flex pt-4 md:pt-0 border-[#83CEC199] border-t-2 md:border-t-0 md:border-b-2 py-[3px] mx-4 md:mx-0 items-start md:items-center justify-end ${
              click ? "flex-col" : "flex-row"
            } `}
          >
            <li className="nav-item1 ">
              <NavLink
                exact
                to="/why_invest_in_pakistan"
                activeClassName="active !text-[14px] md:!text-[16px]"
                className="nav-linksTop !text-[14px] md:!text-[16px]"
                // onClick={handleClick}
              >
                Why Invest in Pakistan?
              </NavLink>
            </li>
            <li className="nav-item1 ">
              <NavLink
                exact
                to="/#success_stories"
                activeClassName="active !text-[14px] md:!text-[16px]"
                className="nav-linksTop !text-[14px] md:!text-[16px]"
                // onClick={handleClick}
              >
                Success Stories
              </NavLink>
            </li>

            <li className="nav-item1 ">
              <NavLink
                exact
                to="/Establishbusinessinpakistan"
                activeClassName="active !text-[14px] md:!text-[16px]"
                className="nav-linksTop !text-[14px] md:!text-[16px]"
                // onClick={handleClick}
              >
                Start Your Business
              </NavLink>
            </li>
            <li className="nav-item1 ">
              <NavLink
                exact
                to="/newsandevents"
                activeClassName="active !text-[14px] md:!text-[16px]"
                className="nav-linksTop !text-[14px] md:!text-[16px]"
                // onClick={handleClick}
              >
                News & Events
              </NavLink>
            </li>
            <li className="nav-item1 ">
              <NavLink
                exact
                to="/insights"
                activeClassName="active !text-[14px] md:!text-[16px]"
                className="nav-linksTop !text-[14px] md:!text-[16px]"
                // onClick={handleClick}
              >
                Insights
              </NavLink>
            </li>
            <li className="nav-item1 ">
              <NavLink
                exact
                to="/FAQs"
                activeClassName="active !text-[14px] md:!text-[16px]"
                className="nav-linksTop !text-[14px] md:!text-[16px]"
                // onClick={handleClick}
              >
                FAQs
              </NavLink>
            </li>

            <NavLink
              exact
              to=""
              activeClassName="activ !text-[14px] md:!text-[16px]e"
              className="nav-linksTop mx-[10px] !text-[14px] md:!text-[16px]"
              onClick={handleClick2}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4888 14.5735L11.6808 10.6129C12.6599 9.44901 13.1964 7.98455 13.1964 6.45999C13.1964 2.89801 10.2983 0 6.73636 0C3.17438 0 0.276367 2.89801 0.276367 6.45999C0.276367 10.022 3.17438 12.92 6.73636 12.92C8.07358 12.92 9.34788 12.5167 10.4374 11.751L14.2743 15.7416C14.4347 15.9082 14.6504 16 14.8816 16C15.1004 16 15.3079 15.9166 15.4655 15.7649C15.8003 15.4428 15.811 14.9085 15.4888 14.5735ZM6.73636 1.68522C9.36923 1.68522 11.5111 3.82713 11.5111 6.45999C11.5111 9.09286 9.36923 11.2348 6.73636 11.2348C4.10349 11.2348 1.96158 9.09286 1.96158 6.45999C1.96158 3.82713 4.10349 1.68522 6.73636 1.68522Z"
                  fill="#F08A23"
                />
              </svg>
            </NavLink>
            {isPopupVisible && <Popup handleClose={handleClosePopup} />}
            {/* <li className="border-l pl-[10px] border-[#f5b921]">
              <div className="language-dropdown bg-transparent">
                <select
                  className="bg-transparent nav-linksTop"
                  id="language"
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                >
                  {languages.map((lang) => (
                    <option key={lang.code} value={lang.code}>
                      {lang.name}
                    </option>
                  ))}
                </select>
              </div>
            FAQli> */}
          </ul>
          <ul
            className={`flex gap-4 md:gap-[36px] pb-4 md:pb-0 mx-4 md:mx-0 items-start md:items-center ${
              click ? "flex-col" : "flex-row"
            } `}
          >
            {/* <li className="nav-item"> */}

            <NavLink
              exact
              to="/"
              activeClassName="active"
              className="nav-link flex align-items-center"
            >
              <span class="mr-1">Home</span>
            </NavLink>

            <div class="dropdown dropdown-navbar-main inline-block  ">
              <div
                activeClassName="active"
                className="nav-link flex align-items-center"
              >
                <span class="mr-1">About Us</span>
                <svg
                  class="fill-current mt-2 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </div>
              <ul
                activeClassName="active"
                class="dropdown-menu  text-left absolute hidden sub_nav_bg !text-white md:!text-black !bg-transparent md:!bg-white pt-1  md:w-[270px] "
              >
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/about"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Why SIFC
                  </NavLink>
                </li>
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/leadership_organization"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Leadership & Organization
                  </NavLink>
                </li>
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/#our_facilitation_spectrum"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Our Facilitation Spectrum{" "}
                  </NavLink>
                </li>
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/core_values"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Our Core Values
                  </NavLink>
                </li>
              </ul>
            </div>
            <div class="dropdown dropdown-navbar-main inline-block ">
              <NavLink
                exact
                to=""
                activeClassName="active"
                className="nav-link flex align-items-center"
              >
                <span class="mr-1">Sectors</span>
                <svg
                  class="fill-current mt-2 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </NavLink>
              <ul
                activeClassName="active"
                class="absolute dropdown-menu hidden pt-1 sub_nav_bg text-left w-[241px] !text-white md:!text-black !bg-transparent md:!bg-white"
              >
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/agricuture-sector"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Agriculture & Livestock
                  </NavLink>
                </li>
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/ITmainPage"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    IT & Telecom
                  </NavLink>
                </li>
                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/MineralsMainPage"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Mines & Minerals
                  </NavLink>
                </li>

                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/EnergyMainPage"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Energy (Petroleum & Power)
                  </NavLink>
                </li>

                <li class="font-normal hover:font-semibold transition-all duration-300 ease-in-out">
                  <NavLink
                    exact
                    to="/IndustryMainPage"
                    activeClassName="active"
                    className="nav-link py-2 px-4 block whitespace-no-wrap"
                  >
                    Industry, Tourism & Privatization
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink
              exact
              to="/investment_climate"
              activeClassName="active"
              className="nav-link"
              // onClick={handleClick1}
            >
              Investment Climate
            </NavLink>

            <NavLink
              exact
              to="/SifcVisa"
              activeClassName="active"
              className="nav-link"
              // onClick={handleClick1}
            >
              SIFC Visa{" "}
            </NavLink>
            {/* </li> */}
            {/* <li className="nav-item"> */}
            <li className="nav-item1">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-link"
                // onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
            {/* </li> */}
          </ul>
        </div>
        <div className="nav-icon" onClick={handleClick1}>
          {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

          {click ? (
            <span className="icon !z-50">
              <HamburgetMenuClose />
            </span>
          ) : (
            <span className="icon !z-50">
              <HamburgetMenuOpen />{" "}
            </span>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
