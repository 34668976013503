import React from "react";
import NavBar from "../NavBar";
function SelectSectors() {
  return (
    <>
      {/* <NavBar/> */}
      This sector page is under development
    </>
  );
}

export default SelectSectors;
