import React, { useState, useEffect } from "react";
import location from "../../../assests/images/location.png";
import calender from "../../../assests/images/calender.png";
import NavBar from "../../NavBar";
// import successSh from "../../assests/images/successDetailsSh.png";
import Footer from "../footer";
import "../../Pages/SuccessStories.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
// import SuccessSlider from "../../subcomponents/SuccessSlider";
import EventButtonScroller from "./eventButtonScroller";
const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export default function EventPage1() {
  const [scrolling, setScrolling] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // console.log(id);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}event/${id}`);
        // console.clear();
        setData(response.data);
      } catch (error) {
        // console.log(error);
        // setError(error);
      } finally {
        // setLoading(false);
        // console.log("final");
      }
    };

    fetchData();
  }, [id]); // Empty dependency array ensures useEffect runs only once (on mount)

  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    // color: scrolling ? "#000" : "#fff",
  };

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {" "}
      <div className="relative pt-16 md:pt-[150px]">
        <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
          <NavBar />
        </nav>
      </div>
      <div className="flex justify-center">
        <div className="flex w-full justify-center px-2 container">
          <div className=" contianer-width flex justify-center flex-col my-8 md:my-20">
            <p className="text-3xl md:text-5xl Success-h-Font text-[#F08A23] text-left mb-2 font-bold">
              {data.title}{" "}
            </p>
            <div className="flex w-full">
              <div className="flex items-center mt-6 mb-4 content-center">
                <img src={location} alt="" />
                <div className="text-[#26532F] ml-2 success-g-font font-light text-base text-center">
                  {data.location}{" "}
                </div>
              </div>
              <div className="flex items-center mt-6 mb-4 ml-6 content-center">
                <img src={calender} alt="" />
                <div className="text-[#26532F] ml-2 success-g-font font-light text-base text-center">
                  {formatDate(data.date)}
                </div>
              </div>
            </div>

            <div
              class="bg-cover bg-top bg-no-repeat h-96 md:h-[540px]"
              style={{
                backgroundImage: `url(${`${myURLForImages}${data.main_image}`})`,
              }}
            >
              <div className=" bg-gradient-to-t from-[#073332] from-10% px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
                <p className="px-4 py-1 bg-[#26532F] text-white max-w-fit text-[10px] news_border">
                  Events
                </p>
              </div>
            </div>
            {/* <div className="h-[50%]">
          <img
            className="my-4 !h-[600px] object-cover "
            src={`${myURLForImages}${data.main_image}`}
            alt=""
          />
        </div> */}
            <p className="success-b-font font-light mt-10 text-xl text-left text-[#323232]">
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </p>
            {/* <p className="success-b-font font-light text-xl mt-10 text-left text-[#323232]"></p>
        <h3 className="text-[#26532F] mt-10 success-b-font text-3xl my-4 font-bold text-left">
          Key Challenges
        </h3>
        <p className="success-b-font font-light mt-10 text-xl text-left text-[#323232]"></p>
        <div className="flex mt-10 w-full h-full gap-8">
          <ul class="success-b-font font-light text-xl flex flex-col text-left text-[#323232] list-disc list-inside gap-10">
            <li>
              <span class="font-bold"></span>
            </li>
            <li>
              <span class="font-bold"></span>
            </li>
            <li>
              <span class="font-bold"></span>
            </li>
            <li>
              <span class="font-bold"></span>
            </li>
          </ul>
          <img className=" w-1/2 h-full" src={detailPic} alt="" />
        </div>
        <h3 className="text-[#26532F] success-b-font text-3xl mt-10 font-bold text-left">
          Driving Efforts with SIFC{" "}
        </h3>
        <p className="success-b-font font-light text-xl mt-10 text-left text-[#323232]"></p>
        <div className="flex w-full h-full mt-10 gap-8">
          <img className=" w-1/2 h-full" src={detailPic} alt="" />
          <ul class="success-b-font font-light text-xl flex flex-col text-left text-[#323232] list-disc list-inside gap-10">
            <li>
              <span class="font-bold"></span>
            </li>
            <li>
              <span class="font-bold"></span>
            </li>
            <li>
              <span class="font-bold"></span>
            </li>
            <li>
              <span class="font-bold"></span>
            </li>
          </ul>
        </div> */}

            <EventButtonScroller />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
