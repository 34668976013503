import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";
// import successsImage1 from "../../assests/images/newsImages/1.webp";
import successsImage2 from "../../assests/images/newsImages/2.png";
// import successsImage3 from "../../assests/images/newsImages/3.webp";
// import successsImage4 from "../../assests/images/newsImages/4.jpg";
// import successsImage5 from "../../assests/images/newsImages/5.jpg";
// import "./abc.css";

const apiUrl = process.env.REACT_APP_API_URL;
const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
const NewsSlider = () => {
  const [news, setNews] = useState([]);
  // const imaeglinkservrt = "https://staging.nitb.gov.pk:8620/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}all-news`);
        setNews(response?.data?.data);
        // console.log("news", response.data);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // console.log("finally");
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  const truncateWords = (text, numWords) => {
    const words = text.split(" ");
    return (
      words.slice(0, numWords).join(" ") +
      (words.length > numWords ? " ..." : "")
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = React.useRef(null);
  slider?.current?.slickGoTo(0);

  return (
    <div className="py-8 md:py-[110px]">
      <div className="flex flex-row justify-between items-baseline ">
        <div className="text-start mt-4 md:mt-10">
          <div className="">
            <h2 className="headingPreviousEvents "> News</h2>
          </div>
        </div>
        <div className="flex flex-row gap-[30px]">
          <span
            className="cursor-pointer"
            onClick={() => slider?.current?.slickPrev()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2594 17.8666L7.39326 10.0005L15.2594 2.13436C15.4914 1.89946 15.6211 1.58227 15.6201 1.25206C15.6191 0.921861 15.4875 0.605467 15.254 0.371976C15.0205 0.138485 14.7041 0.00686749 14.3739 0.00586514C14.0437 0.00486279 13.7265 0.134557 13.4916 0.366626L4.74162 9.11663C4.50721 9.35105 4.37551 9.66898 4.37551 10.0005C4.37551 10.332 4.50721 10.6499 4.74162 10.8844L13.4916 19.6344C13.7265 19.8664 14.0437 19.9961 14.3739 19.9951C14.7041 19.9941 15.0205 19.8625 15.254 19.629C15.4875 19.3955 15.6191 19.0791 15.6201 18.7489C15.6211 18.4187 15.4914 18.1015 15.2594 17.8666Z"
                fill="black"
              />
            </svg>
          </span>{" "}
          <span
            className="cursor-pointer"
            onClick={() => slider?.current?.slickNext()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.74065 17.8666L12.6067 10.0005L4.74065 2.13436C4.50858 1.89946 4.37889 1.58227 4.37989 1.25206C4.38089 0.921861 4.51251 0.605467 4.746 0.371976C4.97949 0.138485 5.29588 0.00686749 5.62609 0.00586514C5.95629 0.00486279 6.27348 0.134557 6.50838 0.366626L15.2584 9.11663C15.4928 9.35105 15.6245 9.66898 15.6245 10.0005C15.6245 10.332 15.4928 10.6499 15.2584 10.8844L6.50838 19.6344C6.27348 19.8664 5.95629 19.9961 5.62609 19.9951C5.29588 19.9941 4.97949 19.8625 4.746 19.629C4.51251 19.3955 4.38089 19.0791 4.37989 18.7489C4.37889 18.4187 4.50858 18.1015 4.74065 17.8666Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>{" "}
      <div class="progress-container mb-4 md:mb-[30px] mt-4 md:mt-[20px]">
        <div class="progress-bar"></div>
      </div>
      {/* <div className="mb-4 md:mb-[30px]">
        <div
          class="bg-cover bg-no-repeat h-96 md:h-[540px]"
          style={{
            backgroundImage: `url(${news})`,
          }}
        >
          <div className=" bg-gradient-to-t from-[#073332] from-10% px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
            <p className="px-4 py-1 bg-[#26532F] text-white max-w-fit text-[10px] news_border">
              News
            </p>
          </div>
        </div>
      </div> */}
      <Slider ref={slider} {...settings}>
        {news &&
          news?.map((news) => (
            <div>
              <Link to={`/news/${news?.id}`} key={news?.id}>
                <div
                  class="bg-cover bg-no-repeat w-[360px] h-[345px] mx-1"
                  style={{
                    backgroundImage: `url(${imaeglinkservrt + news?.image})`,
                  }}
                >
                  <div className=" bg-gradient-to-t from-[#073332] from-10% px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
                    <p className="px-4 py-1 bg-[#26532F] text-white max-w-fit text-[10px] news_border">
                      News
                    </p>
                  </div>
                </div>
                <div className="text-left mt-4">
                  <div className="text-left">
                    <h2 className="text-[18px] font-semibold text-[#26532F]">
                      {truncateWords(news?.title, 4)}
                    </h2>
                  </div>
                  <div className="flex items-center gap-4 my-1">
                    <div className="flex gap-1">
                      {/* <span className="text-[#26532F] text-[12px]">
                        <svg
                          fill="#26532F"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                        >
                          <path d="M12 2a7.008 7.008 0 0 0-7 7c0 5.353 6.036 11.45 6.293 11.707l.707.707.707-.707C12.964 20.45 19 14.353 19 9a7.008 7.008 0 0 0-7-7zm0 16.533C10.471 16.825 7 12.553 7 9a5 5 0 0 1 10 0c0 3.546-3.473 7.823-5 9.533z" />
                          <path d="M12 6a3 3 0 1 0 3 3 3 3 0 0 0-3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1z" />
                        </svg>
                      </span>*/}
                      <p className="text-[#26532F] text-[16px]">
                        Source: {news?.source}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center ">
                      <span className="text-[#26532F] text-[12px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 16 16"
                          fill="#073332"
                        >
                          <path
                            d="M12.6654 2.66634H11.332V1.99967C11.332 1.82286 11.2618 1.65329 11.1368 1.52827C11.0117 1.40325 10.8422 1.33301 10.6654 1.33301C10.4886 1.33301 10.319 1.40325 10.194 1.52827C10.0689 1.65329 9.9987 1.82286 9.9987 1.99967V2.66634H5.9987V1.99967C5.9987 1.82286 5.92846 1.65329 5.80344 1.52827C5.67841 1.40325 5.50884 1.33301 5.33203 1.33301C5.15522 1.33301 4.98565 1.40325 4.86063 1.52827C4.7356 1.65329 4.66536 1.82286 4.66536 1.99967V2.66634H3.33203C2.8016 2.66634 2.29289 2.87705 1.91782 3.25213C1.54274 3.6272 1.33203 4.13591 1.33203 4.66634V12.6663C1.33203 13.1968 1.54274 13.7055 1.91782 14.0806C2.29289 14.4556 2.8016 14.6663 3.33203 14.6663H12.6654C13.1958 14.6663 13.7045 14.4556 14.0796 14.0806C14.4547 13.7055 14.6654 13.1968 14.6654 12.6663V4.66634C14.6654 4.13591 14.4547 3.6272 14.0796 3.25213C13.7045 2.87705 13.1958 2.66634 12.6654 2.66634ZM13.332 12.6663C13.332 12.8432 13.2618 13.0127 13.1368 13.1377C13.0117 13.2628 12.8422 13.333 12.6654 13.333H3.33203C3.15522 13.333 2.98565 13.2628 2.86063 13.1377C2.7356 13.0127 2.66536 12.8432 2.66536 12.6663V7.99967H13.332V12.6663ZM13.332 6.66634H2.66536V4.66634C2.66536 4.48953 2.7356 4.31996 2.86063 4.19494C2.98565 4.06991 3.15522 3.99967 3.33203 3.99967H4.66536V4.66634C4.66536 4.84315 4.7356 5.01272 4.86063 5.13775C4.98565 5.26277 5.15522 5.33301 5.33203 5.33301C5.50884 5.33301 5.67841 5.26277 5.80344 5.13775C5.92846 5.01272 5.9987 4.84315 5.9987 4.66634V3.99967H9.9987V4.66634C9.9987 4.84315 10.0689 5.01272 10.194 5.13775C10.319 5.26277 10.4886 5.33301 10.6654 5.33301C10.8422 5.33301 11.0117 5.26277 11.1368 5.13775C11.2618 5.01272 11.332 4.84315 11.332 4.66634V3.99967H12.6654C12.8422 3.99967 13.0117 4.06991 13.1368 4.19494C13.2618 4.31996 13.332 4.48953 13.332 4.66634V6.66634Z"
                            fill="#26532F"
                          />
                        </svg>
                      </span>
                      <p className="text-[#26532F] text-[16px]">
                        {formatDate(news?.date)}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default NewsSlider;
