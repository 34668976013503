import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import visa1 from "../../assests/images/Rectangle 3476(1).png";
import visa2 from "../../assests/images/Rectangle 3476(2).png";
import "./Home.css";
import Footer from "../subcomponents/footer";
import sifcvisabanner from "../../assests/images/SectorsbannerBG/SIFCBanner.png";
import sifcvisaTabbanner from "../../assests/images/SectorsbannerBG/SIFCTabBanner.png";
import sifcvisaMobileBanner from "../../assests/images/SectorsbannerBG/SIFCmobileBanner.png";
import linkIcon from "../../assests/Icons/link.svg";

function SifcVisa() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  return (
    <>
      <div className="bgPictureSIFCVISA">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureSIFCVISA"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav
              className="w-full text-white fixed top-0 z-50 "
              style={navbarStyle}
            >
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading font-bold ">SIFC VISA</h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 md:mt-[43px] pb-4 md:pb-[43px] pt-4 md:pt-[150px] textvisaheading1">
        How to Apply for SIFC Visa?
      </div>
      <div className="hidden px-[100px] lg:flex flex-row justify-center my-3 mx-4">
        <img src={sifcvisabanner}></img>
      </div>
      <div className="hidden md:flex lg:hidden flex-row justify-center my-3 mx-4">
        <img src={sifcvisaTabbanner}></img>
      </div>
      <div className="flex md:hidden flex-row justify-center my-3 mx-4">
        <img src={sifcvisaMobileBanner}></img>
      </div>
      <div className="flex justify-center">
        <div className="contianer-width">
          <div className="md:py-28 py-4 px-4">
            <div className="flex flex-col-reverse lg:flex-row justify-center gap-8 lg:justify-between">
              <div className="w-full lg:w-2/5">
                <div className="visaboxone">
                  <div className="h-[302px]">
                    <img src={visa1} className=" h-[302px]"></img>
                    <div className="mt-[43px] pb-[43px] textvisaheading1">
                      SIFC - Investor <br></br> Visa
                    </div>
                    <div className="flex flex-row justify-center items-center">
                      <a
                        href="https://visa.nadra.gov.pk/sifc-investor-visa/"
                        target="_blank"
                      >
                        <div className="mt-[40px] butonvisa w-[215px]">
                          Apply for Visa
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/5">
                <div className="headingVISA">SIFC – Investor Visa</div>
                <div>
                  <ul className="textvisss">
                    <li>
                      Ideal for individuals looking to invest in Pakistan's
                      promising sectors
                    </li>
                    {/* <li>Gain privileged access to investment opportunities.</li> */}
                  </ul>
                </div>
                <div className="headung2visa mt-[30px]">Application Type</div>
                <div className="textvisss">
                  You can apply for two types of visas online.
                </div>
                <div className="heading3visa mt-[15px] !text-[#2f7f3d]">
                  New Visa
                </div>
                <div className="grid gap-6 grid-cols-3">
                  <div className="heading4">
                    <a
                      target="_blank"
                      href="https://visa.nadra.gov.pk/business-visa-list-bvl/"
                    >
                      For BVL Countries
                      <img
                        className="w-[20px] cursor-pointer"
                        src={linkIcon}
                      ></img>
                    </a>{" "}
                  </div>
                  <div className="heading4">Duration & Validity</div>
                  <div className="heading4">Processing time</div>
                </div>
                <div className="grid gap-6 grid-cols-3">
                  <div className="textvisss">
                    Investor Short Term 3 Year (Entry)
                  </div>
                  <div className="textvisss">3 Years (Multiple Entries)</div>
                  <div className="textvisss">24 Hours</div>
                </div>
                <div className="grid gap-6 grid-cols-3">
                  <div className="textvisss">Investor Long Term (Entry)</div>
                  <div className="textvisss">5 Years (Multiple Entries)</div>
                  <div className="textvisss">10 Days</div>
                </div>
                <div className="heading3visa mt-[20px] !text-[#2f7f3d]">
                  Extension
                </div>
                <div className="grid gap-6 grid-cols-3">
                  <div className="heading4">
                    {" "}
                    <a
                      target="_blank"
                      href="https://visa.nadra.gov.pk/business-visa-list-bvl/"
                    >
                      For BVL Countries{" "}
                      <img
                        className="w-[20px] cursor-pointer"
                        src={linkIcon}
                      ></img>
                    </a>{" "}
                  </div>
                  <div className="heading4">Duration & Validity</div>
                  <div className="heading4">Processing Time</div>
                </div>
                <div className="grid gap-6 grid-cols-3">
                  <div className="textvisss">Investor Extension</div>
                  <div className="textvisss">2 Years (Multiple Entries)</div>
                  <div className="textvisss">2 Weeks</div>
                </div>

                <div className="headung2visa mt-[30px]">Required Documents</div>
                <div>
                  <div className="textvisss">1. Passport</div>
                  <div className="textvisss">2. Photograph </div>
                  <div className="textvisss">
                    3. Recommendation Letter/Email by SIFC
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:py-28 py-4 px-4">
            <div className="flex flex-col lg:flex-row justify-center gap-8 lg:justify-between">
              <div className="w-full lg:w-3/5">
                <div className="headingVISA">SIFC – Business Visa</div>
                <div>
                  <ul className="textvisss">
                    <li>
                      Designed for individuals seeking to establish or expand
                      their businesses in Pakistan
                    </li>
                    {/* <li>Enjoy streamlined processes and exclusive benefits.</li> */}
                  </ul>
                </div>
                <div className="headung2visa mt-[30px]">Application Type</div>
                <div className="textvisss">
                  You can apply for two types of visas online
                </div>
                <div className="heading3visa mt-[15px] !text-[#2f7f3d]">
                  New Visa
                </div>
                <div className="grid  grid-cols-3 gap-6">
                  <div className="heading4">
                    <a
                      target="_blank"
                      href="https://visa.nadra.gov.pk/business-visa-list-bvl/"
                    >
                      For BVL Countries{" "}
                      <img
                        className="w-[20px] cursor-pointer"
                        src={linkIcon}
                      ></img>
                    </a>{" "}
                  </div>
                  <div className="heading4">Duration & Validity</div>
                  <div className="heading4">Processing time</div>
                </div>
                <div className="grid  grid-cols-3 gap-6">
                  <div className="textvisss">Business Short Term</div>
                  <div className="textvisss">6 Months (Single Entry)</div>
                  <div className="textvisss">24 Hours</div>
                </div>
                <div className="grid  grid-cols-3 gap-6">
                  <div className="textvisss">Business Long Term</div>
                  <div className="textvisss">
                    2 Years (Multiple Entries) validity 5 years
                  </div>
                  <div className="textvisss">24 Hours</div>
                </div>
                <div className="heading3visa mt-[20px] !text-[#2f7f3d]">
                  Extension
                </div>
                <div className="grid  grid-cols-3 gap-6">
                  <div className="heading4">
                    {" "}
                    <a
                      target="_blank"
                      href="https://visa.nadra.gov.pk/business-visa-list-bvl/"
                    >
                      For BVL Countries{" "}
                      <img
                        className="w-[20px] cursor-pointer"
                        src={linkIcon}
                      ></img>
                    </a>{" "}
                  </div>
                  <div className="heading4">Duration & Validity</div>
                  <div className="heading4">Processing time</div>
                </div>
                <div className="grid  grid-cols-3 gap-6">
                  <div className="textvisss">Business Extension</div>
                  <div className="textvisss">2 Years (Multiple Entries)</div>
                  <div className="textvisss">2 Weeks</div>
                </div>

                <div className="headung2visa mt-[30px]">Required Documents</div>
                <div>
                  <div className="textvisss">1. Passport</div>
                  <div className="textvisss">2. Photograph </div>
                  <div className="textvisss">
                    3. Recommendation Letter/Email by SIFC
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5">
                <div className="visaboxone">
                  <div className=" h-[302px]">
                    <img src={visa2} className=" h-[302px]"></img>
                    <div className="mt-[43px] pb-[43px] textvisaheading1">
                      SIFC – Business Visa{" "}
                    </div>
                    <div className="flex flex-row justify-center items-center">
                      <a
                        href="https://visa.nadra.gov.pk/sifc-business-visa/"
                        target="_blank"
                      >
                        <div className="mt-[40px] butonvisa w-[215px]">
                          Apply for Visa
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="Howtoapply mb-[25px]">How to Apply?</div> */}
      {/* <div className="howtoapplyad mb-[10px]">Watch the video</div> */}
      <div className="flex flex-col justify-center items-center mb-4 md:mb-36 mx-4">
        <div className="mt-4 md:mt-[43px] pb-4 md:pb-[43px] headingVISA">
          Visa Guide
        </div>
        <div className="container flex flex-row justify-center items-center">
          <div class="responsive-iframe-container flex flex-row justify-center items-center mx-auto">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/mJFnfSOR0ZA"
              title="Exclusive SIFC Visa: Investor and Business Visa"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        {/* <div className="flex flex-col w-1/2 gap-3 justify-center">
            <div className="textvisaheading1 !text-left">
              How to get SIFC Visa Recommendation?
            </div>
            <div>
              <ul>
                <li className="list-decimal textvisss">
                  {" "}
                  Send your letter request for SIFC Visa to{" "}
                  <span className="!text-[#329244] underline fon">
                    visa@sifc.gov.pk
                  </span>
                </li>
                <li className="list-decimal textvisss">
                  Include in your application:
                  <ul className="px-[20px]">
                    <li className="list-disc">
                      Signed letter to the Secretary SIFC
                    </li>
                    <li className="list-disc">Company profile</li>
                    <li className="list-disc">Complete contact details</li>
                    <li className="list-disc">
                      Proof of business registration
                    </li>
                  </ul>
                </li>
                <li className="list-decimal textvisss">
                  The SIFC Secretariat will issue the Visa invitation letter
                  after a merit-based analysis of your proposal.
                </li>
                <li className="list-decimal textvisss">
                  Feedback from the concerned Ministry/ Department will be
                  obtained if required.
                </li>
              </ul>
            </div>
          </div> */}
      </div>
      <Footer />
    </>
  );
}

export default SifcVisa;
