import React from "react";

export default function ImageDownloadButton(props) {
  function handleDownloadClick() {
    // Create an anchor element
    const anchor = document.createElement("a");
    // Set the href attribute to the image URL
    anchor.href = props.imageUrl;
    // Set the download attribute to specify the filename
    anchor.download = "tender.jpg"; // You can customize the filename here
    // Programmatically click the anchor element to initiate download
    anchor.click();
  }

  return (
    <button
      className="text-left hover:underline text-blue-500 cursor-pointer"
      onClick={handleDownloadClick}
    >
      Download/View
    </button>
  );
}
