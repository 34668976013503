import React, { useState, useEffect } from "react";

const SmoothScrollList = () => {
  const [activeDiv, setActiveDiv] = useState("one");
  const handleDivClick = (divId) => {
    setActiveDiv(divId);
  };
  return (
    <>
      {/* <div className="flex flex-col gap-8 mt-[20px] mx-2">
        <div
          onClick={() => handleDivClick("one")}
          id="one"
          className="flex flex-row lg:flex-nowrap flex-wrap gap-8"
        >
          <div className="flex flex-row  w-full ">
            {/* <div className="NumberMandateTextx">01</div> 
            <div className="w-[12px] bg-[#F08A23] rounded-lg h-[144px] ml-[20px]"></div>
            <div className="flex flex-col ml-4">
              <div className="textHeafingMandate">Single Window </div>
              <div className="textMandate !text-justify">
                To avoid complex business processes and inordinate delays, SIFC
                operates as a dynamic 'Single Window' for ensuring seamless
                facilitation of worthy investors.
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full ">
            {/* <div className="NumberMandateTextx  ">02</div> 
            <div className="w-[12px] bg-[#F08A23] rounded-lg h-[144px] ml-[20px]"></div>

            <div className="flex flex-col ml-[30px]">
              <div className="textHeafingMandate">
                A Central Forum to the Stakeholders{" "}
              </div>
              <div className="textMandate  !text-justify ">
                Provide a central forum to the stakeholders for undertaking
                cross-sectoral reforms and developing an enabling policy
                environment, necessary to improve the investment ecosystem.
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => handleDivClick("two")}
          id="two"
          className="flex flex-row lg:flex-nowrap flex-wrap gap-8"
        >
          <div className="flex flex-row w-full ">
            {/* <div className="NumberMandateTextx">03</div> 
            <div className="w-[12px] bg-[#F08A23] rounded-lg h-[144px] ml-[20px]"></div>

            <div className="flex flex-col ml-[30px]">
              <div className="textHeafingMandate">
                Pakistan's Untapped Potential{" "}
              </div>
              <div className="textMandate  !text-justify">
                SIFC elevates awareness of Pakistan's untapped potential,
                empowering you with informed decision-making to explore
                investment opportunities aligned with your objectives.
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full ">
            {/* <div className="NumberMandateTextx">04</div> 
            <div className="w-[12px] bg-[#F08A23] rounded-lg h-[144px] ml-[20px]"></div>

            <div className="flex flex-col ml-[30px]">
              <div className="textHeafingMandate">Ease of Doing Business </div>
              <div className="textMandate  !text-justify">
                Endeavors to improve ease of doing business by enhancing
                coordination amongst concerned stakeholders and timely
                decision-making and implementation.
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => handleDivClick("three")}
          id="three"
          className="flex flex-row lg:flex-nowrap flex-wrap gap-8"
        >
          <div className="flex flex-row w-full ">
            {/* <div className="NumberMandateTextx">05</div> 
            <div className="w-[6px] bg-[#F08A23] rounded-lg h-[144px] ml-[20px]"></div>

            <div className="flex flex-col  ml-[30px]">
              <div className="textHeafingMandate ">
                Mutual Success & Growth{" "}
              </div>
              <div className="textMandate  !text-justify">
                We aim for mutual success and growth by capitalizing competitive
                advantages.
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full ">
            {/* <div className="NumberMandateTextx">06</div> 
            <div className="w-[6px] bg-[#F08A23] rounded-lg h-[144px] ml-[20px]"></div>

            <div className="flex flex-col ml-[30px]">
              <div className="textHeafingMandate">
                Thriving Business Environment{" "}
              </div>
              <div className="textMandate  !text-justify">
                Our aim is to create a business environment where you thrive.
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="mx-4 w-full hidden md:grid grid-cols-2 gap-4 md:gap-8">
        <div className="flex flex-row w-full ">
          {/* <div className="NumberMandateTextx">01</div> */}
          <div className="hidden md:block w-[12px] bg-[#F08A23] rounded-lg h-[144px] "></div>
          <div className="flex flex-col ml-4">
            <div className="textHeafingMandate">Single Window </div>
            <div className="textMandate !text-justify">
              To avoid complex business processes and inordinate delays, SIFC
              operates as a dynamic 'Single Window' for ensuring seamless
              facilitation of worthy investors.
            </div>
          </div>
        </div>
        <div className="flex  flex-row w-full ">
          {/* <div className="NumberMandateTextx  ">02</div> */}
          <div className="hidden md:block w-[15px] bg-[#F08A23] rounded-lg h-[144px] "></div>

          <div className="flex flex-col ml-4">
            <div className="textHeafingMandate">
              A Central Forum to the Stakeholders{" "}
            </div>
            <div className="textMandate  !text-justify ">
              Provide a central forum to the stakeholders for undertaking
              cross-sectoral reforms and developing an enabling policy
              environment, necessary to improve the investment ecosystem.
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full ">
          {/* <div className="NumberMandateTextx">03</div> */}
          <div className="hidden md:block w-[12px] bg-[#F08A23] rounded-lg h-[144px] "></div>

          <div className="flex flex-col ml-4">
            <div className="textHeafingMandate">
              Pakistan's Untapped Potential{" "}
            </div>
            <div className="textMandate  !text-justify">
              SIFC elevates awareness of Pakistan's untapped potential,
              empowering you with informed decision-making to explore investment
              opportunities aligned with your objectives.
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full ">
          {/* <div className="NumberMandateTextx">04</div> */}
          <div className="hidden md:block w-[12px] bg-[#F08A23] rounded-lg h-[144px] "></div>

          <div className="flex flex-col ml-4">
            <div className="textHeafingMandate">Ease of Doing Business </div>
            <div className="textMandate  !text-justify">
              Endeavors to improve ease of doing business by enhancing
              coordination amongst concerned stakeholders and timely
              decision-making and implementation.
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full ">
          {/* <div className="NumberMandateTextx">05</div> */}
          <div className="hidden md:block w-[6px] bg-[#F08A23] rounded-lg h-[144px] "></div>

          <div className="flex flex-col  ml-4">
            <div className="textHeafingMandate ">Mutual Success & Growth </div>
            <div className="textMandate  !text-justify">
              We aim for mutual success and growth by capitalizing competitive
              advantages.
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full ">
          {/* <div className="NumberMandateTextx">06</div> */}
          <div className="hidden md:block w-[6px] bg-[#F08A23] rounded-lg h-[144px] "></div>

          <div className="flex flex-col ml-4">
            <div className="textHeafingMandate">
              Thriving Business Environment{" "}
            </div>
            <div className="textMandate  !text-justify">
              Our aim is to create a business environment where you thrive.
            </div>
          </div>
        </div>
      </div>
      <div className="grid md:hidden grid-cols-2 md:grid-cols-8 justify-center item-center gap-4 px-4">
        <div className="numbersBoxWhy  ">
          <div className="textHeafingMandate">Single Window </div>
          <div className="textMandate ">
            To avoid complex business processes and inordinate delays, SIFC
            operates as a dynamic 'Single Window' for ensuring seamless
            facilitation of worthy investors.
          </div>
        </div>
        <div className="numbersBoxWhy  ">
          <div className="textHeafingMandate">
            A Central Forum to the Stakeholders{" "}
          </div>
          <div className="textMandate   ">
            Provide a central forum to the stakeholders for undertaking
            cross-sectoral reforms and developing an enabling policy
            environment, necessary to improve the investment ecosystem.
          </div>
        </div>
        <div className="numbersBoxWhy  ">
          <div className="textHeafingMandate">
            Pakistan's Untapped Potential{" "}
          </div>
          <div className="textMandate  ">
            SIFC elevates awareness of Pakistan's untapped potential, empowering
            you with informed decision-making to explore investment
            opportunities aligned with your objectives.
          </div>
        </div>
        <div className="numbersBoxWhy ">
          <div className="textHeafingMandate">Ease of Doing Business </div>
          <div className="textMandate  ">
            Endeavors to improve ease of doing business by enhancing
            coordination amongst concerned stakeholders and timely
            decision-making and implementation.
          </div>
        </div>
        <div className="numbersBoxWhy ">
          <div className="textHeafingMandate ">Mutual Success & Growth </div>
          <div className="textMandate  ">
            We aim for mutual success and growth by capitalizing competitive
            advantages.
          </div>
        </div>
        <div className="numbersBoxWhy  ">
          <div className="textHeafingMandate">
            Thriving Business Environment{" "}
          </div>
          <div className="textMandate  ">
            Our aim is to create a business environment where you thrive.
          </div>
        </div>
      </div>
    </>
  );
};

export default SmoothScrollList;
