import React, { useState, useEffect } from "react";
import ArrowLeft from "../../assests/Icons/fi_5343102.svg";
import ArrowRight from "../../assests/Icons/fi_5343102(1).svg";
import axios from "axios";
import { Link } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;
const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
function SuccessLeadership() {
  const [stories, setStories] = useState([]);
  const [currentLeadership, setCurrentLeadership] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}all-stories`);
        setStories(response.data);
        // console.log(response.data.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, []);
  // const imaeglinkservrt = "https://staging.nitb.gov.pk:8620/";

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  const handleNextLeadership = () => {
    setCurrentLeadership((prevIndex) =>
      prevIndex === stories.length - 1 - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevLeadership = () => {
    setCurrentLeadership((prevIndex) =>
      prevIndex === 0 ? stories.length - 1 - 1 : prevIndex - 1
    );
  };
  const MAX_TITLE_LENGTH = 200; // Define the maximum length you want
  const truncateTitle = (title) => {
    return title.length > MAX_TITLE_LENGTH
      ? title.substring(0, MAX_TITLE_LENGTH) + "..."
      : title;
  };

  // Check if stories is not empty before accessing its elements
  const leadership = stories?.length > 0 ? stories[currentLeadership] : null;

  return (
    <>
      <div className="bg-chief pt-[80px] pb-[50px] flex flex-row flex-wrap justify-center items-center">
        <div className="container-width-SIFC-faclitates">
          <div className="md:relative grid grid-cols-2 items-center">
            <div className="flex z-10 flex-col absolute md:bottom-0">
              <div className="flex flex-row gap-[40px] justify-center items-center w-1/2">
                <div className="flex flex-row gap-[25px] ">
                  <div onClick={handlePrevLeadership}>
                    <img src={ArrowLeft} alt="Previous" />
                  </div>
                  <div onClick={handleNextLeadership}>
                    <img src={ArrowRight} alt="Next" />
                  </div>
                </div>
              </div>
              <div class=" progress-containerSucessStories mt-[25px]">
                <div class="progress-barSucessStories !w-1/2"></div>
              </div>
              <div className="boxleadership1 p-4 md:p-10 w-full md:w-1/2 !bg-[#073332]  mt-[20px]">
                {leadership && (
                  <>
                    <div className="md:pt-[90px] leadershipname1">
                      <Link to={`/success_stories/details/${leadership.id}`}>
                        {truncateTitle(leadership.title)}
                      </Link>
                    </div>
                    <div className="leadershipDesignation1">
                      {formatDate(leadership.date)}
                    </div>
                    <div className="h-[200px] leadershipDetails1 md:mt-[35px]">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateTitle(leadership.description),
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="w-[1010px] h-[646px]">
              {leadership && (
                <img
                  className="object-cover md:w-[1010px] md:h-[646px] absolute right-0"
                  src={imaeglinkservrt + leadership.image}
                  alt="Testimonial"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessLeadership;
