import React, { useState, useEffect } from "react";
import location from "../../../assests/images/location.png";
import calender from "../../../assests/images/calender.png";
import NavBar from "../../NavBar";
// import successSh from "../../assests/images/successDetailsSh.png";
import Footer from "../footer";
import "../../Pages/SuccessStories.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import NewsButtonScroller from "./newsButtonScroller";

const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export default function NewsPage() {
  const [scrolling, setScrolling] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      // console.log("//////////", id);
      // console.log("//////////", apiUrl);
      // console.log("myURLForImages", myURLForImages);
      try {
        const response = await axios.get(`${apiUrl}news/${id}`);
        // console.clear();
        setData(response.data);
        // console.log("====================================");
        // console.log("news", response.data);
        // console.log("====================================");
      } catch (error) {
        // console.log(error);
        // setError(error);
      } finally {
        // setLoading(false);
        // console.log("final");
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once (on mount)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    // color: scrolling ? "#000" : "#fff",
  };

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {" "}
      <div className="relative pt-20 md:pt-[150px]">
        <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
          <NavBar />
        </nav>
      </div>
      <div className="flex justify-center">
        {" "}
        <div className="flex w-full justify-center px-2 container">
          <div className="contianer-width flex justify-center flex-col my-8 md:my-20">
            <p className="text-3xl md:text-5xl Success-h-Font text-[#F08A23] text-left font-bold">
              {data.title}{" "}
            </p>
            <div className="flex w-full">
              <div className="flex items-center mt-2 md:mt-6 mb-2 md:mb-4 content-center">
                {/* <img src={location} alt="" /> */}
                <a
                  className="text-[#26532F] ml-2 success-g-font font-light text-base text-center underline"
                  target="_blank"
                  href={data.url}
                >
                  Source: {data.source}{" "}
                </a>
              </div>
              <div className="flex items-center mt-2 md:mt-6 mb-2 md:mb-4 ml-6 content-center">
                <img src={calender} alt="" />
                <div className="text-[#26532F] ml-2 success-g-font font-light text-base text-center">
                  {formatDate(data.date)}
                </div>
              </div>
            </div>

            <div
              class="bg-cover bg-top bg-no-repeat h-96 md:h-[540px]"
              style={{
                backgroundImage: `url(${`${myURLForImages}${data.image}`})`,
              }}
            >
              <div className=" bg-gradient-to-t from-[#073332] from-10% px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
                <p className="px-4 py-1 bg-[#26532F] text-white max-w-fit text-[10px] news_border">
                  News
                </p>
              </div>
            </div>

            <p className="success-b-font font-light mt-10 text-xl text-left text-[#323232]">
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </p>

            <NewsButtonScroller />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
