import React, { useState, useEffect } from "react";

import TestonimialImage1 from "../../assests/images/Meenah.jpg";
import TestonimialImage2 from "../../assests/images/IMG_7899.JPG";
import TestonimialImage3 from "../../assests/images/IMG_7900.JPG";
import TestonimialImage4 from "../../assests/images/peter.jpg";
import TestonimialImage5 from "../../assests/images/Ijaz Anwar.jpg";
import TestonimialImage6 from "../../assests/images/Syed Muhammad Ali.jpg";
import TestonimialImage7 from "../../assests/images/Fawad Ahmed Mukhtar.jpg";
import TestonimialImage8 from "../../assests/images/Adil Khattak.jpg";
import TestonimialImage9 from "../../assests/images/Mausuf Ahmad.jpg";
import TestonimialImage10 from "../../assests/images/Amir Abbassciy.jpg";
import TestonimialImage11 from "../../assests/images/Faheem Haider.jpg";
import TestonimialImage12 from "../../assests/images/Ismail Suttar.jpg";
import TestonimialImage13 from "../../assests/images/Asad Ahmad.jpg";
import TestonimialImage14 from "../../assests/images/Ahmad Nadeem Khan.jpg";

import ArrowLeft from "../../assests/Icons/fi_5343102.svg";
import ArrowRight from "../../assests/Icons/fi_5343102(1).svg";

function Testimonial() {
  const testimonialsData = [
    //   {
    //     image:,
    //     personName:"  Nizar Mewawalla",
    //     organization:"  IPS, Pakistan",
    //     designation:"Chairman",
    //     text:"  The Special Investment Facilitation Council has played a crucial role in facilitating the approval process for the Solar Project Tariff . This support has enabled the first phase of the 3MW solar power plant in Hunza to commence. This achievement is pivotal in allowing IPS/NPAK Energy to unlock further projects in the Gilgit Baltistan energy sector. SIFC's dedication to resolving issues and promoting sustainable energy solutions is bringing affordable and green energy to the people of Hunza and beyond.",
    // },
    {
      image: TestonimialImage6,
      personName: "Syed Muhammad Ali",
      organization: "Sapphire Chemicals (Pvt) Ltd",
      designation: "Managing Director",
      text: "On behalf of Sapphire Chemicals (Pvt) Ltd, we express our heartfelt gratitude to the Special Investment Facilitation Council (SIFC) for resolving the critical issue of water allocation for our flagship Soda Ash Plant project in District Khushab. The approval of 2.5 cusec water from the Muhajir Branch Canal has been transformative, enabling substantial foreign investment, job creation, and a significant economic boost to the region. We also extend our appreciation to the Government of Punjab for their vital role in supporting this initiative. The SIFC's unwavering support has revitalized business confidence and underscored the potential of public-private collaboration. This project exemplifies our commitment to sustainable economic growth and national self-reliance in critical industrial inputs.",
    },
    // {
    //   image:,
    //   personName:"Muhammad Tasleem Sanaullah",
    //   organization:"Lucky Cement Ltd",
    //   designation:"General Manager",
    //   text:"The Cabinet's decision to allow 7 Companies to install new Cement Plants in Punjab is a testament to the concerted efforts made by team SIFC. SIFC’s  dedication and proactive approach have been instrumental in driving progress and creating new opportunities for growth in the cement industry."
    // ,
    // },

    {
      image: TestonimialImage10,
      personName: "Amir Abbassciy",
      organization: "Cynergico",
      designation: "CEO/Director",
      text: "Speaking on behalf of Cynergico Pakistan Limited, we want to extend our gratitude to the Special Investment Facilitation Council (SIFC) for their ongoing efforts in addressing our issues related with upgradation of biggest oil refinery of Pakistan. Our aim is not just our own success, but also the advancement of Pakistan's energy sector. SIFC's dedication to helping us overcome hurdles in our operations speaks volumes about their commitment to fostering a conducive environment for businesses like ours to thrive.",
    },
    {
      image: TestonimialImage11,
      personName: "Faheem Haider",
      organization: "Mari Petroleum Limited",
      designation: "MD/CEO",
      text: "As a key player in Pakistan's energy landscape, Mari Petroleum Company Limited (MPCL) appreciates the instrumental role of the Special Investment Facilitation Council (SIFC) in promoting economic stability and facilitating investment opportunities. SIFC's support has been pivotal in our joint ventures with foreign investors and in advancing our exploration and production (E&P) activities. The council's efforts have enabled us to boost our operations across Pakistan, reinforcing our commitment to the nation's energy security and economic growth. We look forward to continued collaboration.",
    },
    {
      image: TestonimialImage12,
      personName: "    		Ismail Suttar ",
      organization: " Hub Salt",
      designation: "CEO",
      text: "Hub Salt extends sincere gratitude to SIFC for their outstanding leadership in fast-tracking the international bidding process for our solar salt project in Balochistan, Pakistan. Their visionary guidance and proactive efforts have been instrumental in overcoming hurdles and expediting procedures. We eagerly anticipate continued collaboration with SIFC for the advancement of Pakistan's economic stability.",
    },
    {
      image: TestonimialImage13,
      personName: "Asad Ahmad",
      organization: "Pakistan Mineral Development Corporation (PMDC)",
      designation: "Managing Director/CEO",
      text: "We extend our heartfelt gratitude to the SIFC team for its pivotal role in bringing to fruition the joint venture between PMDC and Miracle Salt Collective Inc. This partnership signifies a significant advancement in our mission to process and export Pink Rock Salt, a long-awaited opportunity for our nation. It underscores the vast potential that Pakistan holds in its natural resources, waiting to be tapped into for the benefit of its people and economy. By focusing on processing and value addition, this venture will not only boost exports but also create job opportunities and enhance the economic value derived from our natural resources.",
    },
    {
      image: TestonimialImage14,
      personName: "Ahmad Nadeem Khan",
      organization: "Board of Directors -Miracle Salt Collective Inc (MSCI)",
      designation: "President & Chairman ",
      text: "Embarking on a joint-venture with Pakistan Mineral Development Corporation was a significant milestone for Miracle Salt Collective, Inc., which was made possible by the invaluable support and guidance of the SIFC. The dedication and expertise of the SIFC team have been instrumental in navigating the complexities of this endeavor. As we prepare for project mobilization, we look forward to continued collaboration with SIFC, confident in their commitment to our mutual success.",
    },
    {
      image: TestonimialImage3,
      text: "I have witnessed Pakistan’s potential delivering lucrative returns for investors. Engro’s diversification over the last 30 years is a testament to the opportunities here. The future, especially in mining, agriculture, and Information Technology, holds tremendous promise. The establishment of SIFC addresses key investor concerns and streamlines regulatory processes.",
      personName: "Ghias Khan",
      designation: "President",
      organization: "Engro Corporation Limited",
    },
    {
      image: TestonimialImage1,
      text: "As the CEO of Metric, a globally recognized AI-powered FIN tech company built in Pakistan, I've witnessed the unprecedented growth of the tech ecosystem here. Investing in Pakistan, especially in the thriving tech sector, is a strategic move, and I invite you to join us on this remarkable journey of opportunity and innovation.",
      personName: "Meenah Tariq",
      designation: "Founder & CEO",
      organization: "Metric",
    },

    {
      image: TestonimialImage2,
      text: "I see immense mining opportunities in Pakistan, exemplified by our Reko Diq project. This world-class venture exemplifies the potential of partnering with Pakistan and signifies strong potential returns. The development of the Reko Diq mine is set to illuminate Pakistan's minerals   potential, marking a pivotal moment for Balochistan's development and the entire country.",
      personName: "Mark Bristow",
      designation: "President & CEO",
      organization: "Barrick Gold",
    },
    // {
    //   image: "as",
    //   text: "We are proud to be part of Pakistan's Green Initiative, dedicated to enhancing global food security. The SIFC streamlines investor collaboration, allowing us to develop a 2,500-acre farm in Khanewal and partner with China in the Thal area. SIFC plays a vital role in facilitating this impactful initiative, offering a one-stop platform for investors contributing to Pakistan's agricultural with significant returns",
    //   personName: "Tahir Aslam",
    //   designation: "MD",
    //   organization: "FonGrow",
    // },
    {
      image: TestonimialImage4,
      text: "Our $60 million investment in a manufacturing plant has thrived with the support of SIFC. This collaboration facilitated crucial connections with the government, enhancing both our exports and local presence. To new investors, I encourage exploring opportunities in Pakistan – it's truly worth it",
      personName: "Peter Hauggaard",
      designation: "CEO       ",
      organization: "Ecolean",
    },
    {
      image: TestonimialImage5,
      text: "Scatec has recently operationalised its three solar power plants in Sukkur, Pakistan, totalling 150MWs. Soon after establishment of SIFC, Scatec approached SIFC for assistance to resolve challenges faced by the projects. SIFC’s prompt and effective intervention and support resolved many of those challenges, allowing the operationalization of the plants. SIFC's commitment to their mandate translated into tangible results.",
      personName: "Ijaz Anwar",
      designation: "Country Manager Pakistan ",
      organization: "Scatec ASA",
    },
    {
      image: TestonimialImage7,
      personName: "Fawad Ahmed Mukhtar",
      organization: "Fatima Fertilizers",
      designation: "CEO",
      text: "As we continue to navigate the complexities of the fertilizer sector, SIFC's expertise and support have proven invaluable. We are optimistic about the positive impact of their initiatives and remain confident in their ability to drive sustainable growth and prosperity for the fertilizer industry and the nation as a whole in ensuring food security.",
    },
    {
      image: TestonimialImage8,
      personName: "Adil Khattak",
      organization: "Attock Refinery Limited",
      designation: "CEO",
      text: "SIFC's dedicated efforts have injected momentum into the resolution of our long-standing issues related to the upgradation of brownfield refineries, breaking through bureaucratic hurdles and paving the way for meaningful progress. We are deeply thankful for their proactive support in improving energy landscape of Pakistan.",
    },
    {
      image: TestonimialImage9,
      personName: "Mausuf Ahmad ",
      organization: "United Energy Pakistan",
      designation: "President",
      text: "Allow me to extend our sincerest gratitude to the Special Investment Facilitation Council (SIFC) for their invaluable assistance in navigating the challenges we are encountering. SIFC's proactive approach reflects a commitment to fostering a conducive environment for investment and growth in Pakistan's E&P sector.",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(
    (0 - 1 + testimonialsData.length) % testimonialsData.length
  );

  const handleNextTestimonial = () => {
    setCurrentTestimonial(
      (prevIndex) => (prevIndex + 1) % testimonialsData.length
    );
  };

  const handlePrevTestimonial = () => {
    setCurrentTestimonial(
      (prevIndex) =>
        (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
    );
  };

  const testimonial = testimonialsData[currentTestimonial];

  return (
    <>
      {" "}
      <div className="px-4 md:p-20 testnemonials flex   gap-8 flex-row justify-center item-center">
        <div className=" contianer-width  grid grid-cols-1 lg:grid-cols-2 gap-[30px] justify-center item-center py-4">
          {/* <div></div> */}
          <div className="flex justify-around w-full lg:hidden headingTestonimial text-[24px]  leading-none md:text-[38px] lg:text-[48px] text-center  lg:mb-[40px]">
            <div className="headingTestonimial leading-none text-center text-[48px] ">
              Testimonials
            </div>

            <div className="flex items-center justify-center sm:mx-[10px] lg:justify-start  flex-row gap-[25px]">
              <div onClick={handlePrevTestimonial}>
                <img src={ArrowLeft} alt="Previous" />
              </div>
              <div onClick={handleNextTestimonial}>
                <img src={ArrowRight} alt="Next" />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <img
              className=" md:h-[524px] h-[260px] w-[360px]  md:w-full lg:w-[751px]"
              src={testimonial.image}
              alt="Testimonial"
            />{" "}
          </div>
          <div className="flex flex-col  w-full text-center lg:text-left">
            <div className="hidden lg:block headingTestonimial text-[24px] sm:text-[48px]  lg:mb-[40px]">
              <div className="flex flex-row items-center">
                <div className="headingTestonimial leading-none text-center  text-[24px] sm:text-[48px] ">
                  Testimonials
                </div>
                <div className="flex items-center justify-center sm:mx-[10px] lg:justify-start  flex-row gap-[25px]">
                  <div onClick={handlePrevTestimonial}>
                    <img src={ArrowLeft} alt="Previous" />
                  </div>
                  <div onClick={handleNextTestimonial}>
                    <img src={ArrowRight} alt="Next" />
                  </div>
                </div>
              </div>
            </div>
            {/*   font-size: 18px;
             */}
            <div className="testnomianlText text-justify !text-[16px] lg:!text-[18px] sm:mx-[10px] ">
              {testimonial.text}
            </div>
            <div className="mt-4 md:mt-[30px] lg:mt-[35px] sm:mx-[10px] personNameTestnimial md:!text-[28px] !text-lg">
              {testimonial.personName}
            </div>
            <div className="sm:mx-[10px]">
              <div className="Designation">{testimonial.designation}</div>
              <div className="Designation">{testimonial.organization}</div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
export default Testimonial;
