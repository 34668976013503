import React, { useState, useEffect } from "react";
import location from "../../../assests/images/location.png";
import calender from "../../../assests/images/calender.png";
import NavBar from "../../NavBar";
// import successSh from "../../assests/images/successDetailsSh.png";
import Footer from "../footer";
import "../../Pages/SuccessStories.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import NewsButtonScroller from "./newsButtonScroller";

const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export default function PressReleasedetails() {
  const [scrolling, setScrolling] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      //   console.log("//////////", id);
      //   console.log("//////////", apiUrl);
      //   console.log("myURLForImages", myURLForImages);
      try {
        const response = await axios.get(`${apiUrl}press/${id}`);
        // console.clear();
        setData(response.data);
      } catch (error) {
        // console.log(error);
        // setError(error);
      } finally {
        // setLoading(false);
        // console.log("final");
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once (on mount)

  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    // color: scrolling ? "#000" : "#fff",
  };

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {" "}
      <div className="relative pt-[80px] md:pt-[200px]">
        <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
          <NavBar />
        </nav>
      </div>
      <div className=" items-center flex flex-col">
        <div className="contianer-width px-4 flex justify-center flex-col my-6 md:my-8">
          <p className="text-3xl md:text-5xl Success-h-Font text-[#F08A23] text-left mb-2 font-bold">
            {data.title}{" "}
          </p>
          <div className="flex w-full">
            <div className="flex items-center mt-6 mb-4 content-center">
              <img src={location} alt="" />
              <div className="text-[#26532F] ml-2 success-g-font font-light text-base text-center">
                Islamabad{" "}
              </div>
            </div>
            <div className="flex items-center mt-6 mb-4 ml-6 content-center">
              <img src={calender} alt="" />
              <div className="text-[#26532F] ml-2 success-g-font font-light text-base text-center">
                {formatDate(data.date)}
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="relative">
              {data.image && (
                <>
                  <img
                    src={`${myURLForImages}${data.image}`}
                    alt="1"
                    className="h-[467px] w-[1531px] object-contain"
                  />
                  <div class="absolute bottom-0 bg-gradient from-transparen"></div>
                </>
              )}
              {/*  */}
            </div>
          </div>

          <p className="success-b-font font-light mt-10 text-xl text-left text-[#323232]">
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </p>

          {/* <NewsButtonScroller /> */}
        </div>
      </div>
      <Footer />
    </>
  );
}
