import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import partner1 from "../../assests/images/partner (2).png";
import partner4 from "../../assests/images/partner (4).png";
import partner5 from "../../assests/images/partner (5).png";
import partner6 from "../../assests/images/partner (6).png";
import partner7 from "../../assests/images/partner (7).png";
import partner8 from "../../assests/images/partner (8).png";

import partner9 from "../../assests/images/partner (3).png";
import partner10 from "../../assests/images/partner (9).png";
import partner11 from "../../assests/images/partner (11).png";
import partner12 from "../../assests/images/partner (10).png";
import partner13 from "../../assests/images/partner (1).png";
import Marquee from "react-fast-marquee";

import partner14 from "../../assests/images/partner (9)(1).png";
import partner15 from "../../assests/images/partner (3)(1).png";
import "./Home.css";
import Footer from "../subcomponents/footer";
function Partners() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  return (
    <>
      <div className="bgPicturePartners">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPicturePartners"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav
              className="w-full text-white fixed top-0 z-50 "
              style={navbarStyle}
            >
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading md:!text-[50px] !text-[35px] font-bold ">
                  Our Partners
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 md:py-[150px] container-width flex flex-col justify-center items-center">
        <Marquee>
          <div className="flex flex-row gap-[30px]">
            <div className="partnerBox">
              <img src={partner1}></img>
            </div>
            <div className="partnerBox">
              <img src={partner4}></img>
            </div>
            <div className="partnerBox">
              <img src={partner5}></img>
            </div>
            <div className="partnerBox">
              <img src={partner6}></img>
            </div>
            <div className="partnerBox">
              <img src={partner7}></img>
            </div>
            <div className="partnerBox">
              <img src={partner8}></img>
            </div>
            <div className="partnerBox">
              <img src={partner7}></img>
            </div>
            <div className="partnerBox">
              <img src={partner8}></img>
            </div>
          </div>
        </Marquee>
        <div className="pt-[70px] px-[150] flex flex-row justify-center items-center">
          <Marquee direction="right">
            <div className="flex flex-row gap-[30px]">
              <div className="partnerBox">
                <img src={partner9}></img>
              </div>
              <div className="partnerBox">
                <img src={partner10}></img>
              </div>
              <div className="partnerBox">
                <img src={partner11}></img>
              </div>
              <div className="partnerBox">
                <img src={partner12}></img>
              </div>
              <div className="partnerBox">
                <img src={partner13}></img>
              </div>
              <div className="partnerBox">
                <img src={partner9}></img>
              </div>
              <div className="partnerBox">
                <img src={partner10}></img>
              </div>
              <div className="partnerBox">
                <img src={partner11}></img>
              </div>
              <div className="partnerBox">
                <img src={partner12}></img>
              </div>
              <div className="partnerBox">
                <img src={partner13}></img>
              </div>
              <div className="partnerBox">
                <img src={partner11}></img>
              </div>
              <div className="partnerBox">
                <img src={partner12}></img>
              </div>
              <div className="partnerBox">
                <img src={partner13}></img>
              </div>
            </div>
          </Marquee>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Partners;
