import React, { useEffect, useState } from "react";
import axios from "axios";
import "./newsandevents.css";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// import successsImage1 from "../../assests/images/eventsandnews/en1.png";
import CardSlider from "./EventScrollereventpage";
import NewsSlider from "./NewsScrollerNewsPage";
const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
const Newsandevents = () => {
  const [data, setData] = useState([]);
  const [Events, setEvents] = useState([]);

  const [firstEvent, setFirstEvent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}press-releases`);

        setData(response.data);
        // console.log(response.data);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // console.log("final");
      }
      try {
        const response = await axios.get(`${apiUrl}all-events`);
        setEvents(response.data);
        setFirstEvent(response.data[0]); // Fix the typo here
        // console.log("events", response.data.data);
        // console.log("events Detail wwwwwwwwwwwwwwwwwPage", firstEvent);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // console.log("finally");
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const truncateWords = (text, numWords) => {
    const words = text.split(" ");
    return (
      words.slice(0, numWords).join(" ") +
      (words.length > numWords ? " ..." : "")
    );
  };
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    const truncateWords = (text, numWords) => {
      const words = text.split(" ");
      return (
        words.slice(0, numWords).join(" ") +
        (words.length > numWords ? " ..." : "")
      );
    };
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  return (
    <div>
      <div className="bgPictureNewsandEvents">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureNewsandEvents"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav
              className="w-full text-white fixed top-0 z-50 "
              style={navbarStyle}
            >
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="aboutusHeading font-bold !text-3xl md:!text-6xl">
                  News & Events{" "}
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* carousol  Events */}
      <div class="container mx-auto px-4">
        <div className="hidden md:block text-start py-10 md:mt-10">
          <div className=" border-b flex justify-between">
            <h2 className="headingPreviousEvents font-semibold border-bottom w-40 ">
              Events
            </h2>
            <h2 className="headingPreviousEvents font-semibold border-bottom w-40 ">
              Press Releases
            </h2>
          </div>
        </div>

        <div>
          <div className="flex flex-col md:flex-row items-start relative gap-7">
            <div className="w-full md:w-[75%] h-full">
              {firstEvent && ( // Check if firstEvent is defined before rendering
                <>
                  <div className="block md:hidden text-start py-10 md:mt-10">
                    <h2 className=" headingPreviousEvents font-semibold border-bottom w-40 ">
                      Events
                    </h2>
                  </div>
                  <div
                    class="bg-cover bg-no-repeat h-96 md:h-[540px]"
                    style={{
                      backgroundImage: `url(${
                        myURLForImages + firstEvent.main_image
                      })`,
                    }}
                  >
                    <div className=" bg-gradient-to-t from-[#073332] from-10% px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
                      {firstEvent && ( // Check if firstEvent is defined before rendering
                        <Link to={`/event/${firstEvent.id}`}>
                          <div className="text-left">
                            <p className="px-4 py-1 bg-[#26532F] text-white max-w-fit  text-[10px] news_border">
                              Event
                            </p>
                            <h2 className="text-[24px] text-white">
                              {truncateWords(firstEvent.title, 6)}
                            </h2>
                          </div>
                          <div className="flex items-center gap-4 my-4">
                            <div className="flex gap-1">
                              <span className="text-white">
                                <svg
                                  class="w-6 h-6 text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                  />
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17.8 14h0a7 7 0 1 0-11.5 0h0l.1.3.3.3L12 21l5.1-6.2.6-.7.1-.2Z"
                                  />
                                </svg>
                              </span>
                              <p className="text-white text-[16px]">
                                {firstEvent.location}{" "}
                              </p>
                            </div>
                            <div className="flex gap-1">
                              <span className="text-white text-[12px]">
                                <svg
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                >
                                  {/* ... */}
                                </svg>
                              </span>
                              <p className="text-white text-[16px]">
                                {formatDate(firstEvent.date)}
                              </p>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="w-full md:w-[25%] text-start">
              <div className="block md:hidden text-start py-10 md:mt-10">
                <h2 className=" block md:hidden headingPreviousEvents font-semibold border-bottom w-40 ">
                  Press Releases
                </h2>
              </div>
              <div className="flex flex-col gap-2 h-96 md:h-[540px] overflow-y-auto scroll_webkit">
                {data &&
                  data.map((news) => (
                    <Link to={`/press-release/${news.id}`} key={news.id}>
                      <div className="border-b pr-3">
                        <h3 className="text-[20px] text-[#26532F]">
                          {news.title}{" "}
                        </h3>

                        <div className="flex gap-1">
                          <p className="text-[#26532F] text-[16px] pb-4">
                            {/* {formatDate(news.date)} */}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <CardSlider />
        {/* Previous Events */}
        {/* News carousol  */}
        <NewsSlider />
      </div>

      {/* News carousol  */}

      <Footer />
    </div>
  );
};

export default Newsandevents;
