import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import "./WhyInvest.css";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Establishbusinessinpakistan() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 10000 });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    // color: scrolling ? "#000" : "#fff",
  };
  const [data, setData] = useState([]);
  const [i, setI] = useState(0);
  function init() {
    setInterval(() => {
      setI((prevI) => (prevI === banners.length - 1 ? 0 : prevI + 1));
    }, 150000);
  }

  useEffect(() => init(), []);

  let banners = [
    {
      image: "./images/InvestimagesPak/Business.png",
      hero: "Register Your Business",
      list: [
        {
          text: "Visit  SECP website to register with different entities  eservices.secp.gov.pk  ",
        },
        { text: "Registration with FBR, EOBI, STZA, PSW, PEC, PSEB" },
        {
          text: "Registration with provincial ESSIs, Industries, Excise & Taxation ",
        },
      ],
      // Visit eservices.secp.gov.pk to register with different entities through single window
      // Registration with SECP
      // Registration with FBR, EOBI, STZA, PSW, PEC, PSEB
      // Registration with provincial ESSIs, Industries, Excise & Taxation
    },
    {
      image: "./images/InvestimagesPak/Opening.png",
      hero: "Opening of Bank Account ",

      list: [
        {
          text: "Open a business bank account with any commercial bank in Pakistan",
        },
      ],
    },
    {
      image: "./images/InvestimagesPak/ForeignExchangeCompliance.png",
      hero: "Licenses & Permits",
      list: [
        {
          text: "Register & obtain authorization from relevant regulators (federal, provincial & local governments) as per your business activity",
        },
      ],
    },
    {
      image: "./images/InvestimagesPak/Liscense.png",
      hero: "Foreign Exchange Compliance",
      // tagline: "   Invest in the Bounty of Pakistan's Agricultural Potential",
      // list: "",
      list: [
        {
          text: "Obtain State Bank of Pakistan's approval for foreign exchange remittance",
        },
        {
          text: "Register foreign investment & facilitate repatriation of foreign exchange with SBP",
        },
        {
          text: "Visit sbp.org.pk",
        },
      ],
    },
    {
      image: "./images/InvestimagesPak/EnvironmentCompliance.png",
      hero: "Environmental Compliance ",
      list: [
        {
          text: " 	Registration with Environmental Protection Agency for Environmental Impact Assessment",
        },
        {
          text: " Register at Federal & Provincial Levels",
        },
        {
          text: " Visit  https://environment.gov.pk/",
          // authorities",
        },
      ],
      //       Identify and secure industry-specific licenses and permits
      // Register with relevant regulators as per your business activity
      // Obtain necessary permits for land: utilities, and building approval plans from relevant
      // authorities
      // tagline: "   Invest in the Bounty of Pakistan's Agricultural Potential",
      // list: "Identify and secure industry-specific licenses and permits,      Register with relevant regulators as per your business activity,      Obtain necessary permits for land: utilities, and building approval plans from relevant      authorities",
    },
    {
      image: "./images/InvestimagesPak/Visa.png",
      hero: "Visa ",
      // tagline: "   Pakistan's Mineral Wealth Awaits",
      list: [
        {
          text: "Apply for SIFC Visa under Business & Investor categories",
        },
      ],
      // list: "Complete import/export registration on Pakistan Single Window (PSW) with the FBR.Familiarize yourself with customs and trade regulations      ",
    },
  ];
  const handleDotClick = (index) => {
    setI(index);
  };
  return (
    <>
      <div className="bgPictureInsights">
        {/* Navbar */}
        <div className="relative">
          <nav
            className="w-full text-black fixed top-0 z-50 "
            style={navbarStyle}
          >
            <NavBar />
          </nav>
        </div>
      </div>
      <div className="mt-16 md:mt-44 pb-[25px] flex flex-col justify-center items-center">
        <div className="AgricultureMainHeading !text-3xl md:!text-5xl mt-[50px]">
          Establish Your Business in Pakistan
        </div>
        <div className="AgricultureMainText contianer-width py-1 px-3">
          Invest confidently in Pakistan with these streamlined steps, ensuring
          a smooth entry and compliance with local regulations.
        </div>
      </div>
      <div className="container-width flex flex-col justify-center items-center">
        <div
          class="bg-cover bg-center bg-no-repeat w-11/12 xl:w-[60%] h-96 md:h-[450px]"
          style={{
            backgroundImage: `url(${banners[i].image})`,
          }}
        >
          <div className="flex flex-col w-full h-full content-center justify-center items-center">
            <h1
              className="heroHeading text-[28px] md:text-[40px] !text-white   font-bold "
              style={{
                transition: "background-image 1s ease",
              }}
            >
              {banners[i].hero}
            </h1>{" "}
            <ul className="text-lg list-disc text-white p-4 text-left Poppins px-8">
              {banners[i].list.map((item, index) => (
                <li className=""> {item.text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center my-4 md:my-[30px]">
        <div className="contianer-width flex flex-col justify-center  gap-2">
          <div className=" flex flex-row items-center justify-between mx-2  overflow-x-auto">
            {banners.map((_, index) => (
              <div
                key={index}
                className={` cursor-pointer Poppins ${
                  index === i ? "text-[#329244]" : "text-[#B5B5B5]"
                }`}
                onClick={() => handleDotClick(index)}
              >
                <div
                  data-aos="fade-right"
                  className="flex flex-row items-center justify-center gap-5"
                >
                  <p
                    className={`Poppins text-base w-[120px] text-center ${
                      index === i ? "text-[#329244]" : "text-[#B5B5B5]"
                    }`}
                  >
                    {banners[index].hero}
                  </p>
                  {index < banners.length - 1 && (
                    <div className="dotted-line"></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="w-full flex justify-center items-center my-[70px]">
        <div className="contianer-width flex flex-col  gap-2">
          <div className="contianer-width text-base Poppins my-[20px]">
            <ul className=" list-disc">
              <li>ESSIs: Employees Social Security Institutions</li>
              <li>EOBI: Employees' Old-Age Benefits Institution</li>
              <li>EPA: Environmental Protection Agency</li>
              <li>PSW: Pakistan Single Window</li>
              <li>PEC: Pakistan Engineering Council</li>
              <li>PSEB: Pakistan Software Export Board</li>
              <li>SBP: State Bank of Pakistan</li>
              <li>SECP: Securities & Exchange Commission of Pakistan</li>
              <li>STZA: Special Technology Zones Authority</li>
            </ul>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}
