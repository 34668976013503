import React, { useState, useEffect } from "react";
import pmimage from "../../assests/Icons/Rectangle 3441(1).png";
import ACimage from "../../assests/images/pm pic copy.jpg";
import ArrowLeft from "../../assests/Icons/fi_5343102.svg";
import ArrowRight from "../../assests/Icons/fi_5343102(1).svg";

function Leadership() {
  const LeadershipData = [
    {
      image: ACimage,
      // text: "With over 240 million inhabitants, Pakistan offers its investors a strong and large consumer market with an ever expanding middle class. Strategic location at the crossroads of South Asia, Central Asia and West Asia and close proximity to the Gulf countries, makes Pakistan a promising regional hub and an important market for intra and inter-regional trade and investment. Pakistan as an investment destination, offers investor friendly policies with the commitment to resolve and enhance the business competitiveness in the region. Almost all sectors of economy are open to investment with attractive incentives and liberal policies to give investors a competitive and thriving business environment.",
      text: "Within the intricate fabric of global investment landscapes, the Special Investment Facilitation Council stands as a testament to Pakistan's unwavering commitment to fostering a robust and conducive business environment. In a nation of over 240 million individuals, we present a dynamic market, offering unparalleled opportunities for growth and prosperity.",
      personName: "Mian Muhammad Shehbaz Sharif",
      designation: "Prime Minister",
      organization: "Islamic Republic of Pakistan      ",
      id: "#PM",
    },

    {
      image: pmimage,
      text: "Our country is generously bestowed by Allah Almighty with abundant resources and a nation with zeal, talent and resilience. Together, we have always stood up to myriad challenges with unwavering resolve and today’s economic woes are also scalable, provided we harness our latent potential. In a quest to rekindle the economy, Government of Pakistan launched Economic Revival Plan for attracting investments. At the heart of this endeavour stands Special Investment Facilitation Council (SIFC), which envisions to transform our economic approach – debt-driven to investment-driven.",
      personName: "General Syed Asim Munir, NI (M)",
      designation: "Chief of the Army Staff",
      organization: "Islamic Republic of Pakistan",
      id: "#COAS",
    },
    // Add more testimonials here...
  ];

  const [currentLeadership, setCurrentLeadership] = useState(0);

  const handleNextLeadership = () => {
    setCurrentLeadership(
      (prevIndex) => (prevIndex + 1) % LeadershipData.length
    );
  };

  const handlePrevLeadership = () => {
    setCurrentLeadership(
      (prevIndex) =>
        (prevIndex - 1 + LeadershipData.length) % LeadershipData.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextLeadership();
    }, 5000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [currentLeadership]);

  const leadership = LeadershipData[currentLeadership];
  return (
    <>
      <div className="bg-chief py-4 md:pt-[195px] xxl:px-[150px] 2xl:px-0 flex flex-row flex-wrap justify-center item-center">
        <div className="container-width-SIFC-faclitates">
          <div className=" relative grid grid-cols-1 xl:grid-cols-2 gap-8 items-center">
            <div className="flex z-10  justify-center items-center flex-col">
              <div className="flex flex-row gap-[20px] xl:gap-[40px] !items-center">
                <div className="headingTestonimial leading-none text-left text-[40px] ">
                  Our Leadership
                </div>
                <div className="flex  flex-row gap-[25px]">
                  <button onClick={handlePrevLeadership}>
                    <img src={ArrowLeft} alt="Previous" />
                  </button>
                  <button onClick={handleNextLeadership}>
                    <img src={ArrowRight} alt="Next" />
                  </button>
                </div>
              </div>
              <img
                className="object-cover block text-center xl:hidden mt-[20px] xl:mt-0 w-1/2"
                src={leadership.image}
                alt="Testimonial"
              />

              <div className="xl:p-[40px]">
                <div
                  className="boxleadership xl:bg-[#fcfcfc] xl:shadow-[0px 26px 50px 0px rgba(34, 87, 77, 0.15)]
                 p-[20px] xxl:p-[40px] !items-center xl:!items-start w-full  !mt-[0px] xl:!mt-[20px]"
                >
                  <div className=" leadershipname !text-[18px] md:!text-[28px] w-full text-left">
                    {leadership.personName}
                  </div>
                  <div className="leadershipDesignation w-full text-left">
                    {leadership.designation}
                  </div>
                  <div className="leadershipDesignation w-full text-left">
                    {leadership.organization}
                  </div>

                  <div className=" !mx-[0px]  leadershipDetails text-[14px] xl:text-[21px] xxl:text-[20px] 2xl:text-[21px] leading-3 text-justify mt-[10px]">
                    {leadership.text}
                  </div>
                  <a
                    href={"/leadership_organization" + leadership.id}
                    className="mt-4 md:mt-[28px] flex flex-row gap-2 items-center align-center  justify-center"
                  >
                    {" "}
                    <p className=" DigitalPakistanLearnMore !text-[#329244] cursor-pointer">
                      Read more{" "}
                    </p>{" "}
                    <svg
                      class="w-6 h-6 !text-[#329244]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <img
              className="object-cover hidden  xl:block max-w-xl   xl:w-[620px] xl:h-[690px]"
              src={leadership.image}
              alt="Testimonial"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Leadership;
