// Accordion component
import React, { useState } from "react";

const Accordion = ({ icon, title, content }) => {
  const [isActive, setIsActive] = useState(false);

  const handleAccordionClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="accordion-item my-[7px]">
      <div
        className="flex flex-row justify-between cursor-pointer"
        onClick={handleAccordionClick}
      >
        <div className="flex flex-row items-center gap-[20px]">
          <div>
            <img src={icon} alt="Accordion Icon" />
          </div>
          <div className="dropdownheading !text-base md:!text-[24px]">
            {title}
          </div>
        </div>
        <button
          className={`!text-[#2F7F3D] !font-semibold !text-lg `}
          // transition-transform transform ${isActive ? "rotate-45" : ""}
        >
          {isActive ? "-" : "+"}
        </button>
      </div>
      {isActive && (
        <div className="dropdownText mt-[20px] text-left">
          {title === "Initiate Contact" ? (
            <a className="" href="mailto:invest@sifc.gov.pk">
              {content}
            </a>
          ) : (
            content
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
