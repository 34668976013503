// Popup.js

import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cross from "../../assests/images/icons8-cross-30(1).png";
const apiUrl = process.env.REACT_APP_API_URL;
const myURLForImages = process.env.REACT_APP_STAGGING_URL_FOR_Images;
const Popup = ({ handleClose }) => {
  const [searchText, setSearchText] = useState("");
  const [data, setSearchResult] = useState(null);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      try {
        // Make an API call using Axios
        const response = await axios.get(`${apiUrl}search?query=${searchText}`);
        setSearchResult(response?.data);

        // Handle the fetched data as needed
        console.log("Fetched data:", response.data);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <div className="popup w-full relative p-6 border rounded-lg">
      <input
        type="text"
        placeholder="Enter Search text "
        value={searchText}
        className="text-black border p-2 rounded text-sm"
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      {data && (
        <div className="text-black text-left text-sm">
          <h3 className="text-base font-bold text-center my-2">
            Search Result:
          </h3>
          <div>
            {/* Events */}
            {data?.Events && data?.Events?.length > 0 && (
              <div>
                <h2 className="text-base font-bold">Events:</h2>
                <ul>
                  {data?.Events?.map((event) => (
                    <li key={event?.id}>
                      <Link to={`/event/${event?.id}`}>{event?.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Stories */}
            {data?.Stories && data?.Stories?.length > 0 && (
              <div>
                <h2 className="text-base font-bold">Stories:</h2>
                <ul>
                  {data?.Stories?.map((story) => (
                    <li key={story?.id}>
                      <Link to={`/success_stories/details/${story?.id}`}>
                        {story?.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* News */}
            {data?.News && data?.News?.length > 0 && (
              <div>
                <h2 className="text-base font-bold">News:</h2>
                <ul>
                  {data?.News?.map((news) => (
                    <li key={news?.id}>
                      <Link to={`/news/${news?.id}`}>{news?.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Press Releases */}
            {data.PressReleases && data.PressReleases.length > 0 && (
              <div>
                <h2 className="text-base font-bold">Press Releases:</h2>
                <ul>
                  {data.PressReleases.map((pressRelease) => (
                    <li key={pressRelease.id}>
                      <Link to={`/press-release/${pressRelease.id}`}>
                        {pressRelease.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
      <button
        className="text-black Poppins text-base absolute top-0 right-0 "
        onClick={handleClose}
      >
        <img src={cross} alt="Close" />
      </button>
    </div>
  );
};

export default Popup;
