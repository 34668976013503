import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WP1 from "../../assests/Icons/fi_4411931.svg";
import WP2 from "../../assests/Icons/fi_3580045.svg";
import WP3 from "../../assests/Icons/fi_1017498.svg";
import WP4 from "../../assests/Icons/fi_11495729.svg";
import WP5 from "../../assests/Icons/Vector(5).svg";
import WP6 from "../../assests/Icons/fi_7713671.svg";
import WP7 from "../../assests/Icons/fi_12773441.svg";
import WP8 from "../../assests/Icons/fi_10378798.png";
import AOS from "aos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
function WhyInvestinPakistan() {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);
  return (
    <div
      id="why_invest"
      className="bg-whyInvest-in-Pakistan flex justify-center items-center flex-col mb-[20px] md:mb-[40px]  "
    >
      <div className="flex-col justify-center items-center flex">
        <div
          data-aos="fade-down"
          className="headingInvestInPakistan md:!text-[40px] !text-[30px] md:mb-[40px] mx-4  z-0"
        >
          Why Invest in Pakistan ?
        </div>
      </div>
      <div className="container-width-Why-Invest ">
        <div className="mt-[18px] md:mt-[45px] mb-[20px] md:mb-[70px] grid grid-cols-2 md:grid-cols-4 gap-4 place-content-center !text-center mx-4">
          <Link
            to="/why_invest_in_pakistan/#geo-strategy"
            className=" py-[40px] WhyInvestBox"
            onClick={() => {
              const element = document.getElementById("geo-strategy");
              // console.log(element); // Check the console for the logged element
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            <img src={WP1}></img>
            <div className="WP_text">Geo-Strategic Location</div>
          </Link>
          <Link
            to="/why_invest_in_pakistan#young_&_skilled"
            className="  py-[40px] WhyInvestBox"
          >
            <img src={WP6}></img>
            <div className="WP_text !text-center">
              Vibrant, Young & Skilled Workforce
            </div>
          </Link>
          <Link
            to="/why_invest_in_pakistan#EnormousPotential"
            className="  py-[40px] WhyInvestBox"
          >
            <img src={WP7}></img>
            <div className="WP_text">Enormous Potential</div>
          </Link>
          <Link
            to="/why_invest_in_pakistan#NetworkofEconomic"
            className="  py-[40px] WhyInvestBox"
          >
            <img src={WP2}></img>
            <div className="WP_text !text-center">
              Network of Economic & Tech Zones
            </div>
          </Link>
          <Link
            id="Liberal"
            to="/why_invest_in_pakistan#Liberal"
            className="md:col-start-2  py-[40px] WhyInvestBox"
          >
            <img src={WP3}></img>
            <div className="WP_text">Liberal Investment Regime</div>
          </Link>
          <Link
            to="/why_invest_in_pakistan#Modernized"
            className="  py-[40px] WhyInvestBox"
          >
            <img src={WP8}></img>
            <div className="WP_text">Modernized Infrastructure Landscape</div>
            {/* <div className="whyInvestPakistan">
              Enabling investment activities
            </div> */}
          </Link>
          {/* <Link            to="/why_invest_in_pakistan"
 className="  py-[40px] WhyInvestBox">
            <img src={WP5}></img>
            <div className="WP_text">Consumer Market</div>
            <div className="whyInvestPakistan">Surging local demands</div>
          </Link> */}
        </div>
      </div>
      {/* <div className="h-[200px]"></div> */}
    </div>
  );
}

export default WhyInvestinPakistan;
